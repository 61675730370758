import {HostListener, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  formSpreeUrl = environment.formspree;
  httpOptions = {
    headers: new HttpHeaders( {
        'Content-Type' : 'application/json'
    })
  };
  pageYOffset = new BehaviorSubject(0);

  constructor(private http: HttpClient) { }

  public sendMail(body): Observable<any> {
    return this.http.post(this.formSpreeUrl, body, this.httpOptions);
  }

  scrollToElement(element): void {
    const el = document.getElementById(element);
    el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  setPageYOffset(pageYOffset): void {
    this.pageYOffset.next(pageYOffset);
  }

  getPageYOffset(): Observable<any> {
    return this.pageYOffset;
  }

}
