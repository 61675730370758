import {Component, HostListener, OnInit} from '@angular/core';
import {AppServiceService} from '../app-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  mobileExpand = false;
  showNavBg = false;

  @HostListener('window:scroll', ['$event']) onScrollEvent = $event => {
    const pageYOffset = window.pageYOffset;
    // send to service so other components can have scroll info
    this.appService.setPageYOffset(pageYOffset);

    // add navbar background
    const jumbotron = window.document.getElementsByClassName('jumbotron')[0];
    this.showNavBg = pageYOffset > (jumbotron.scrollHeight / 3);
  }

  constructor(private appService: AppServiceService) { }

  ngOnInit(): void {
  }

  scrollToElement(element): void {
    this.appService.scrollToElement(element);
  }

  expandMobileNav(): any {
    this.mobileExpand = !this.mobileExpand;
  }

}
