<footer class="bg-dark p-5">
  <div class="container">
    <div class="row">
      <section class="col-sm-12 col-md-4">
        <a target="_blank" href="http://www.facebook.com/voirin" class="fa fa-facebook-square"></a>
        <a target="_blank" href="http://www.linkedin.com/in/voirin" class="fa fa-linkedin-square"></a>
      </section>
      <nav class="col-sm-12 col-md-8">
        <ul>
          <li>
            <a href="javascript:void(0)" (click)="scrollToElement('about')">About Me</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="scrollToElement('what')">What I Do</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="scrollToElement('experience')">Experience</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="scrollToElement('open-source')">Open Source</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="scrollToElement('contact')">Contact</a>
          </li>
          <li>
            <a target="_blank" href="assets/Resume_Jason_Voirin.pdf">Resume</a>
          </li>
        </ul>
      </nav>
      <div class="col-sm-12 copyright">
        <p>Copyright © 2021 Jason Voirin. All rights reserved.</p>
      </div>
    </div>
  </div>
</footer>
