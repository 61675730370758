<section class="jumbotron" id="top">
  <div class="container" [style.opacity]="headingOpacity">
    <h1 class="animated-caption active">Hello, I'm Jason</h1>
    <p class="animated-caption active">Husband. Father. Developer. Rancher.</p>
  </div>
</section>

<app-about></app-about>

<section class="what p-5" id="what">
  <div class="container-fluid">
    <h2 class="header-white mt-5">What I Do</h2>
    <p class="text-center text-white pb-3">
      In short, I provide architectural solutions and lead development on enterprise software applications and big data managed on cloud infrastructure.
    </p>
    <div class="row pt-5">
      <div class="col-sm-12 col-md-6 col-xl-3 text-center text-white">
        <i class="fa fa-code pb-3"></i>
        <h4>Software</h4>
        <p>9+ years in full-stack enterprise application development using the latest light-weight and scalable technologies.</p>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-3 text-center text-white">
        <i class="fa fa-desktop pb-3"></i>
        <h4>Web Development</h4>
        <p>15+ years in corporate and free-lance responsive web development.</p>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-3 text-center text-white">
        <i class="fa fa-mobile pb-3"><span class="fa fa-tablet"></span></i>
        <h4>Mobile Applications</h4>
        <p>10+ years in mobile web and hybrid applications development across both iOS and Android platforms using responsive front-end technologies, RESTful API services and Cordova/PhoneGap.</p>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-3 text-center text-white">
        <i class="fa fa-picture-o pb-3"></i>
        <h4>Graphic Design</h4>
        <p>13+ years experience in graphic design creating and modifying web and print digital assets using Adobe Suite platform.</p>
      </div>
    </div>
  </div>
</section>

<app-experience></app-experience>

<section class="open-source bg-blue text-white p-5" id="open-source">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="open-source-icon text-center mb-3">
          <i class="fa fa-code"></i>
        </div>
        <h2 class="header-white">My Open-Source Code</h2>
        <p class="text-center mb-5">After years of relying on the open-source community, I am extremely proud to give back. I created ngFader and angular-formspree!</p>
      </div>
      <div class="col-lg-6 col-sm-12 mb-5">
        <img class="img-fluid" src="assets/img/code.PNG">
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="mb-5">
          <h4>Angular-Formspree</h4>
          <p>A simple contact form component that uses FormSpree services. No PHP or server-side languages required — perfect for static sites! The contact form used below is none other.</p>
          <a href="https://github.com/jasonvoirin/angular-formspree" target="_blank" class="arrow-link-blue-light">Angular-Formspree on Github</a>
        </div>
        <div>
          <h4>ngFader</h4>
          <p>ngFader is a simple, responsive and pure AngularJS fading slideshow directive. It requires ngAnimate for the image fading transitions and ngTouch for tap/swipe mobile gestures. Other than those two dependancies - which you probably use already - it stands alone. Install, list your images in the directive "images" array and you are done!
          </p>
          <a href="https://github.com/jasonvoirin/ngFader" target="_blank" class="arrow-link-blue-light">ngFader on Github</a>
        </div>
      </div>
    </div>
  </div>
</section>


<app-contact></app-contact>
