import { Component, OnInit } from '@angular/core';
import {AppServiceService} from '../app-service.service';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm = this.fb.group({
    name: ['', Validators.required],
    _replyto: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required]
  });

  sendingMail = false;
  sendMailSuccess = false;
  sendMailFailure = false;

  phoneNumber = '469-734-7155';
  emailAddress = 'jasonvoirin@gmail.com';

  constructor(private appService: AppServiceService, private fb: FormBuilder) {

  }

  ngOnInit(): void {
  }

  public submitContact(): any {
    this.sendingMail = true;
    const body = this.contactForm.getRawValue();
    this.appService.sendMail(body).subscribe(res => {
      if (res.ok) {
        this.sendMailSuccess = true;
        this.sendMailFailure = false;
      } else {
        this.sendingMail = false;
        this.sendMailFailure = true;
        this.sendMailSuccess = false;
      }
    });
  }

}
