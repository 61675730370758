import { Component, OnInit } from '@angular/core';
import {AppServiceService} from '../app-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // https://getbootstrap.com/docs/4.0/examples/

  headingOpacity = 1;

  constructor(private appService: AppServiceService) {
  }

  ngOnInit(): void {
    this.appService.getPageYOffset().subscribe(pageYOffset => {
      const jumbotron = window.document.getElementsByClassName('jumbotron')[0];

      if (pageYOffset <= jumbotron.scrollHeight) {
        this.headingOpacity = 1 - pageYOffset / (jumbotron.scrollHeight / 2);
      } else {
        this.headingOpacity = 0;
      }
    });
  }



}
