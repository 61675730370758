<header class="navbar navbar-expand-md navbar-dark fixed-top" [class.bg-dark]="showNavBg">
  <div class="container-fluid">
    <h1><a class="text-white" href="javascript:void(0)" (click)="scrollToElement('top')">Jason Voirin</a></h1>
    <nav role="navigation">
      <div class="fa fa-navicon text-white" (click)="expandMobileNav()"></div>
      <ul class="nav navbar" [class.showMobileNav]="mobileExpand">
        <li>
          <a class="text-white pr-4 d-block" href="javascript:void(0)" (click)="scrollToElement('about')">About Me</a>
        </li>
        <li>
          <a class="text-white pr-4 d-block" href="javascript:void(0)" (click)="scrollToElement('what')">What I Do</a>
        </li>
        <li>
          <a class="text-white pr-4 d-block" href="javascript:void(0)" (click)="scrollToElement('experience')">Experience</a>
        </li>
        <li>
          <a class="text-white pr-4 d-block" href="javascript:void(0)" (click)="scrollToElement('open-source')">Open Source</a>
        </li>
        <li>
          <a class="text-white pr-4 d-block" href="javascript:void(0)" (click)="scrollToElement('contact')">Contact</a>
        </li>
        <li>
          <a class="text-white pr-4 d-block" target="_blank" href="assets/Resume_Jason_Voirin.pdf">Resume</a>
        </li>
      </ul>
    </nav>
  </div>
</header>
