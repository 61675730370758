import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Experience} from './experienceDataModel';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  experienceData = [];
  featuredExp: Experience;
  expCollectionRef: any;
  category: string;

  constructor(private store: AngularFirestore, private modalService: NgbModal) {

    this.category = 'all';

    this.expCollectionRef = this.store.collection('experience', ref => ref.orderBy('sortOrder'));
    this.expCollectionRef.get().subscribe(res => {
      if (!res.empty) {
        res.forEach(item => {
          this.experienceData.push(item.data());
        });
      }
    });
  }

  ngOnInit(): void {
  }

  changeFilter(cat): void {
    this.category = cat;
  }

  open(content, item): any {
    this.featuredExp = item;
    this.modalService.open(content, {
      size: 'xl',
      keyboard: false,
      centered: true
    });
  }

}

@Pipe({
  name: 'experienceFilter',
  pure: false
})
export class ExperiencePipe implements PipeTransform {
  transform(items: [], category: string): any {
    if (!items) {
      return items;
    }
    return items.filter(item => (item[category]));
  }
}
