<section class="about-me p-5" id="about">
  <div class="container">
    <h2 class="header-black">A little about myself</h2>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-5">
        <img alt="Jason Voirin" class="w-75 m-auto d-block rounded" src="assets/img/profile_pic.JPG">
      </div>
      <div class="col-sm-12 col-lg-6">
        <p>First, I have been married 24 years to my high school sweetheart. Second, I am a proud father of 3 children - Connor, Dawson and Faith. They are the reason I work so hard.</p>
        <p>I am a developer by day and a cattle rancher by night. My wife and I saved our money for many years and bought a cattle ranch in northeastern Collin County, Texas. When things get stressful, I love to enjoy time with my family and my cows. When I have time, I enjoy playing guitar, drums, and coin collecting.</p>
        <p>For more information on our ranch, visit our <a target="_blank" href="https://rockingvcattle.com">Rocking V Cattle</a> website and follow us on Instagram for the latest photos of what we are doing on our ranch.</p>
        <p>Let's stay connected. Find me on <a target="_blank" href="https://www.facebook.com/voirin">Facebook</a> or connect with me on <a target="_blank" href="https://www.linkedin.com/in/voirin">LinkedIn</a>. If you have time, check out my <a target="_blank" href="https://github.com/jasonvoirin">GitHub</a> repository.</p>
        <div class="text-center">
          <a target="_blank" href="https://www.facebook.com/voirin" class="fa fa-facebook icon-social fb"></a>
          <a target="_blank" href="https://www.linkedin.com/in/voirin" class="fa fa-linkedin icon-social lin"></a>
          <a target="_blank" href="https://github.com/jasonvoirin" class="fa fa-github-alt icon-social gh"></a>
        </div>
      </div>
    </div>
  </div>
</section>
