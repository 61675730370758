<section class="contact p-5" id="contact">
  <div class="container">
    <h2 class="header-black">My Contact Info</h2>
    <div class="row m-auto text-center">
      <p class="col-lg-6 offset-lg-3 pb-5">I would love to hear from you! Feel free to shoot me an email, text/call my mobile or fill out the contact form and I will get back with you as soon as possible.</p>
      <div class="col-sm-12 col-lg-3 mb-5 offset-lg-3">
        <i class="fa fa-envelope fa-2x d-block mb-3 text-blue"></i>
        <a [href]="'mailto:' + emailAddress" class="text-dark">{{emailAddress}}</a>
      </div>
      <div class="col-sm-12 col-lg-3 mb-5">
        <i class="fa fa-phone fa-2x d-block mb-3 text-blue"></i>
        <a [href]="'tel:+' + phoneNumber" class="text-dark">{{phoneNumber}}</a>
      </div>
      <div class="col-sm-12 col-lg-6 offset-lg-3">
        <div [formGroup]="contactForm">
          <div class="form-group">
            <input class="form-control input-lg" placeholder="Name" formControlName="name">
          </div>
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" formControlName="_replyto">
          </div>
          <div class="form-group">
            <textarea class="form-control" formControlName="message"></textarea>
          </div>
          <div class="form-group">
            <button [disabled]="sendingMail || contactForm.invalid" (click)="submitContact()" class="btn btn-dark bg-blue border-0 text-white pr-3 pl-3 pt-1 pb-1 rounded">Submit</button>
          </div>
          <p *ngIf="sendMailSuccess" class="font-weight-bold text-success">Thank you! Your message has been received.</p>
          <p *ngIf="sendMailFailure" class="font-weight-bold text-danger">Oops! Your message was not sent.</p>
        </div>
      </div>
    </div>
  </div>
</section>
