import { Component, OnInit } from '@angular/core';
import {AppServiceService} from '../app-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private appService: AppServiceService) { }

  ngOnInit(): void {
  }

  scrollToElement(element): void {
    this.appService.scrollToElement(element);
  }

}
