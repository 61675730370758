<section id="experience" class="experience p-5">
  <div class="container">
    <h2 class="header-black">My Journey</h2>
    <ul class="d-none d-md-block col-sm-12 text-center mb-5">
      <li class="btn border mr-2 ml-2" (click)="changeFilter('all')" [ngClass]="{'bg-blue text-white':category=='all'}">All</li>
      <li class="btn border mr-2 ml-2" (click)="changeFilter('web')" [ngClass]="{'bg-blue text-white':category=='web'}">Web Development</li>
      <li class="btn border mr-2 ml-2" (click)="changeFilter('mobile')" [ngClass]="{'bg-blue text-white':category=='mobile'}">Mobile Development</li>
      <li class="btn border mr-2 ml-2" (click)="changeFilter('graphic')" [ngClass]="{'bg-blue text-white':category=='graphic'}">Graphic Design</li>
    </ul>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let item of experienceData | experienceFilter:category; let i = index">
        <div class="exp-thumb-wrapper border p-3 text-center mb-5 card">
          <img class="card-img w-75 m-auto pb-4 d-block" alt="{{item.alt}}" [src]="item.img">
          <h5 class="card-title">{{item.title}}</h5>
          <p class="card-text">{{item.content}}</p>
          <p><a target="_blank" [href]="'https://'+item.url">{{item.url}}</a></p>
          <a href="javascript:void(0)" class="btn btn-sm bg-blue text-white w-50 m-auto" (click)="open(content, item)">Read more</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="p-5 mb-5">
  <div class="container">
    <div class="d-md-flex justify-content-center d-none">
      <p class="d-block mt-2 mb-0"><strong>Want more?</strong> Download my resume to see more in depth information on my work history.</p>
      <a class="btn bg-blue text-white ml-5" href="assets/Resume_Jason_Voirin.pdf" target="_blank">My Resume</a>
    </div>
  </div>
</section>

<ng-template #content let-modal let-c="close">
  <div class="experience-view p-5">
    <button type="button" class="close" aria-label="Close" (click)="c('Close')">
      <span aria-hidden="true">×</span>
    </button>
    <div class="row">
      <div class="col-sm-6 heading">
        <img class="img-small mb-5" [src]="featuredExp.img" alt="{{featuredExp.alt}}">
        <h4>Company URL:</h4> <p><a [href]="'https://'+featuredExp.url" target="_blank">{{featuredExp.url}}</a></p>
        <p *ngIf="featuredExp.mobile" class="mt-5">
          <i class="fa fa-apple"></i>
          <i class="fa fa-android"></i>
        </p>
      </div>
      <div class="col-sm-6 heading">
        <h4>Role:</h4> <p>{{featuredExp.title}}</p>
        <h4>Summary:</h4> <p class="ng-binding">{{featuredExp.viewContent}}</p>
        <h4 *ngIf="featuredExp.technologies">Technologies include:</h4>
        <p *ngIf="featuredExp.technologies">{{featuredExp.technologies}}</p>
        <h4>Development Tools:</h4> <p>{{featuredExp.devTools}}</p>
      </div>
    </div>
  </div>
</ng-template>
