export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCdeRASArmEr9MQ1DNQvji1btMLFRK7-y8',
    authDomain: 'jason-voirin.firebaseapp.com',
    databaseURL: 'https://jason-voirin.firebaseio.com',
    projectId: 'jason-voirin',
    storageBucket: 'jason-voirin.appspot.com',
    messagingSenderId: '76047002575',
  },
  formspree: 'https://formspree.io/f/xknkggqr'
};
